
import QuizModel from '@/views/worldMap/contentFolder/model/subject/QuizModel'
import MissionModel from '@/views/worldMap/contentFolder/model/subject/MissionModel'

export default class SubjectModel {
  constructor(){
    this.objRaw = {};
    this.id = null;
    this.placeId = null; // 장소 id
    this.courseId = null; // 코스 id
    this.questId = null; // 퀘스트 id
    this.quizType = ''; // 퀴즈 타입 QUIZ / MISSION
    this.quizContentType = ''; // OX-OX형태, SELECTONE-양자택일, AUTOCHECK-자동체크, SELFCHECK - 자가체크
    this.quizContents = {};
    // this.applyCountry = 'GLOBAL'; // 메타정보 GLOBAL, KOREA
    this.tags = [];
    this.quizArea = 'KNOWLEDGE'; // 역량 KNOWLEDGE-지식, NEWS-시사, ANALYSIS-분석력, THINKING-사고력, VALUES-가치관
    this.position = 0; // 순서
    this.status = ''; // 상태
    this.checked = false; // 검수상태
    this.historyDataList = []; // 생성,수정 정보
  }
  setCurrentData(placeId,courseId,questId,quizType){
    this.placeId = placeId; // 장소 id
    this.courseId = courseId; // 코스 id
    this.questId = questId; // 퀘스트 id
    this.quizType = quizType; // 퀴즈 타입
    this.setQuestContents(quizType)
  }
  setData(obj){
    if( obj ) this.objRaw = JSON.parse(JSON.stringify(obj));
    const { id, placeId, courseId, questId, quizType, quizContentType, quizContents, tags, quizArea, position, status, checked, createdAt, createdBy, updatedAt, updatedBy } = obj;
    this.id = id;
    this.placeId = placeId;
    this.courseId = courseId;
    this.questId = questId;
    this.quizType = quizType;
    this.quizContentType = quizContentType;
    // this.applyCountry = applyCountry;
    this.tags = tags;
    this.quizArea = quizArea;
    this.position = position;
    this.status = status;
    this.checked = checked;
    this.historyDataList = [
      {
        by: createdBy,
        at: createdAt,
        type: '생성'
      },
      {
        by: updatedBy,
        at: updatedAt,
        type: '수정'
      }
    ];
    this.setQuestContents(quizType,quizContents)
  }
  setQuestContents(quizType,quizContents){
    if(quizType){
      switch(quizType){
        case 'QUIZ' : {
          this.quizContents = new QuizModel();
          break
        }
        case 'MISSION' : {
          this.quizContents = new MissionModel();
          break
        }
      }
      if(quizContents){
        this.quizContents.setData(quizContents);
      }
    }
  }
  getData(){
    if(!this.id){
      return this.getRegisterData()
    }else{
      return this.getModifyData()
    }
  }
  getRegisterData(){
    let obj = {
      questId : this.questId,
      quizType : this.quizType,
      quizContentType : this.quizContentType,
      quizContents : this.quizContents.getRegisterData(),
      tags : this.tags,
      status: "CLOSED",
      quizArea : this.quizArea,
    }
    return obj;
  }
  getModifyData(){
    const tagUpdate = JSON.stringify(this.tags) != JSON.stringify(this.objRaw.tags);
    const tagIdFilter = this.tags.length > 0 ? this.tags.map(item => item.id) : [];
    let obj = {
      questId : this.questId,
      quizType : this.quizType,
      quizContentType : this.quizContentType,
      quizContents : this.quizContents.getModifyData(),
      tagUpdate : tagUpdate,
      tags : tagIdFilter,
      quizArea : this.quizArea,
      status : this.status,
      checked: this.checked,
    }
    return obj;
  }
}