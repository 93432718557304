<template>
  <div class="inner_cont inner_lesson_cont">
    <strong class="tit_lesson">{{ lessonData.title }}</strong>
    <p class="desc_subtitle">{{ lessonData.sub_title }}</p>
    <div class="lesson_contents" v-html="lessonData.contents"></div>
    <div class="group_btn">
      <Button
        btnSize="medium"
        btnStyle="primary"
        :text="lessonBtnText"
        @onClickBtn="$emit('goNextPage')" />
    </div>
  </div>
</template>

<script>
import Button from '@/components/common/button/Button';

export default {
  name: 'Lesson',
  props:{
    lessonData: Object
  },
  components:{
    Button,
  },
  computed:{
    lessonBtnText(){
      if(this.lessonData.lesson_position === 'BEFORE'){
        return this.$poData.quiz_lesson_intro_btn
      }else{
        return this.$poData.quiz_lesson_outro_btn
      }
    }
  },
}
</script>

<style scoped>
.inner_lesson_cont{
  display:flex;
  flex-direction:column;
  height:100%;
  padding:0 20px}
.tit_lesson{flex:0 1;display:block;padding:20px 0 0;font-weight:700;font-size:24px;line-height:30px}
.desc_subtitle{flex:0 1;padding:12px 0 0;font-weight:600;font-size:14px;line-height:22px;color:#999}
.lesson_contents{flex:1 1;padding:16px 0 24px;font-weight:500;font-size:16px;line-height:24px}
.lesson_contents::v-deep .tit_comm{margin-bottom:13px;font-weight:800;font-size:18px;line-height:28px;letter-spacing:-0.5px}
.lesson_contents::v-deep img{display:block;width:100%;margin:16px 0}
.lesson_contents::v-deep a{color:#2D7CD9;text-decoration:underline}

.lesson_contents::v-deep  [data-name="title_content"]{display:block;margin-bottom:16px;font-weight:700;font-size:20px;line-height:28px}
.lesson_contents::v-deep  [data-name="txt_highlight"]{display:inline-block;background-image: linear-gradient( transparent 41%, #D8CBF4 41% 88%, transparent 88% 100%);vertical-align:top}

.group_btn{flex:0 1;margin:0;padding:0 0 30px;}
.group_btn button{margin:0}
</style>