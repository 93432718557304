import { getMoneyToNumber } from '@lemontree-ai/lemontree-admin-common-front/utils/stringUtils';
export default class PlaceModel {
  constructor(){
    this.id = null; // 장소 id
    this.stage = null; // stage
    this.name = ''; // 장소명_한글
    this.engName = ''; // 장소명_영어
    this.width = 0;
    this.height = 0;
    this.x = 0;
    this.y = 0;
  }
  setData(obj){
    const { id, stage, name, engName, width, height, x, y } = obj;
    this.id = id;
    this.stage = stage;
    this.name = name;
    this.engName = engName;
    this.width = width;
    this.height = height;
    this.x = x;
    this.y = y;
  }
  getData(){
    let obj = {
      id : this.id,
      name : this.name,
      stage : this.stage,
      engName : this.engName,
      width : this.width,
      height : this.height,
      x : this.x,
      y : this.y,
    }
    return obj;
  }
}