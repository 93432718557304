export default class QuestModel {
  constructor(){
    this.objRaw = {};
    this.id = null;
    this.placeId = null; // 장소 id
    this.courseId = null; // 코스 id
    this.quizType = 'QUIZ'; // 퀘스트 타입 QUIZ / MISSION
    this.name = ''; // 퀘스트 명
    this.memo = ''; // 퀘스트 메모
    this.lessonPosition = 'NONE'; // 레슨타입
    this.lesson = { // 레슨
      title: '', // 레슨 제목
      subTitle: '', // 레슨 부제목
      contents: '', // 레슨 본문 HTML
    };
    this.position = null; // 순서
    this.status = ''; // 상태
    this.historyDataList = []; // 생성,수정 정보
  }
  setCurrentData(placeId,courseId){
    this.placeId = placeId; // 장소 id
    this.courseId = courseId; // 코스 id
  }
  setData(obj){
    if( obj ) this.objRaw = JSON.parse(JSON.stringify(obj));
    const { id, placeId, courseId, quizType, name, memo, lessonPosition, lesson, position, status, createdAt, createdBy, updatedAt, updatedBy } = obj;
    this.id = id;
    this.placeId = placeId;
    this.courseId = courseId;
    this.quizType = quizType;
    this.name = name;
    this.memo = memo;
    this.lessonPosition = lessonPosition;
    this.lesson = lesson || { // 레슨
      title: '', // 레슨 제목
      subTitle: '', // 레슨 부제목
      contents: '', // 레슨 본문 HTML
    };
    this.position = position;
    this.status = status;
    this.historyDataList = [
      {
        by: createdBy,
        at: createdAt,
        type: '생성'
      },
      {
        by: updatedBy,
        at: updatedAt,
        type: '수정'
      }
    ]
  }
  getData(){
    if(!this.id){
      return this.getRegisterData()
    }else{
      return this.getModifyData()
    }
  }
  getRegisterData(){
    let obj = {
      placeId : this.placeId,
      courseId : this.courseId,
      quizType : this.quizType,
      name : this.name,
      memo : this.memo,
      lessonPosition : this.lessonPosition,
    }
    if(this.lessonPosition != 'NONE'){
      obj.lesson = this.lesson;
    }
    return obj;
  }
  getModifyData(){
    let obj = {
      id : this.id,
      placeId : this.placeId,
      courseId : this.courseId,
      name : this.name,
      memo : this.memo,
      lessonPosition : this.lessonPosition,
      status : this.status,
    }
    if(this.lessonPosition != 'NONE'){
      obj.lesson = this.lesson;
    }
    return obj;
  }
}