export default class LessonModel {
  constructor(){
    this.title = ''; // 레슨 제목
    this.subTitle = ''; // 레슨 부제목
    this.contents = ''; // 레슨 본문 HTML
  }
  setData(obj){
    if(!obj) return;
    const { title, subTitle, contents } = obj;
    this.title = title;
    this.subTitle = subTitle;
    this.contents = contents;
  }
  getRegisterData(){
    let obj = {
      title : this.title,
      subTitle : this.subTitle,
      contents : this.contents,
    }
    return obj;
  }
  getModifyData(){
    let obj = {
      title : this.title,
      subTitle : this.subTitle,
      contents : this.contents,
    }
    return obj;
  }
}