<template>
  <Popup
    :title="data.id ? `퀴즈 퀘스트 수정` : `새로운 퀴즈 퀘스트 추가`"
    :maxWidth="1000"
    closeBtnText="닫기"
    :isDisabeldCompeleteBtn="vaild"
    compeleteBtnText="저장"
    @onClickClose="$emit('onClickPopupClose')"
    @onClickComplete="$emit('onClickPopupComplete',data.getData())">
    <InfoDosc
      v-if="data.id"
      :status="data.status"
      statusConvertIdToColor="contents_status"
      :dataList="data.historyDataList"/>
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:170px"/>
        <col style="width:100px"/>
        <col/>
        <col style="width:100px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>코스 (상위폴더)<IconSvg v-if="!isDisabledCourse" iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td colspan="4">
            <div class="group_form">
              <Selectbox
                :dataList="dataViewModel.PLACE.dataList"
                :isDisabled="isDisabledCourse"
                :value="data.placeId"
                placeholder="장소선택"
                :showLine="9"
                @update:value="value => onUpdatePlace(value)"/>
              <span class="txt_center">></span>
              <Selectbox
                :dataList="dataViewModel.COURSE.dataList | setSelectDataList('id', 'name')"
                :isDisabled="isDisabledCourse || !data.placeId"
                :value.sync="data.courseId"
                :placeholder="!data.placeId ? '장소를 선택해주세요':'코스선택'"
                :showLine="9"/>
            </div>
          </td>
        </tr>
        <tr>
          <th>퀴즈 퀘스트 명<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td colspan="4">
            <Input
              :placeholder="`퀴즈 퀘스트 명`"
              :value.sync="data.name"/>
          </td>
        </tr>
        <tr>
          <th>메모</th>
          <td colspan="4">
            <Textarea
              placeholder="메모"
              :value.sync="data.memo"
              :max="100"
              :showLine="4"
              :isCount="true"/>
          </td>
        </tr>
        <tr>
          <th :rowspan="isLesson ? 4 : 1">레슨<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <th>타입</th>
          <td colspan="3">
            <Radio
              :dataList="lessonDataList"
              :value.sync="data.lessonPosition"/>
          </td>
        </tr>
        <template v-if="isLesson">
          <tr>
            <th>제목</th>
            <td colspan="3">
              <Input
                placeholder="레슨 제목"
                :value.sync="data.lesson.title"/>
            </td>
          </tr>
          <tr>
            <th>부제목</th>
            <td colspan="3">
              <Input
                placeholder="레슨 부제목"
                :value.sync="data.lesson.subTitle"/>
            </td>
          </tr>
          <tr>
            <th>본문 HTML</th>
            <td colspan="3">
              <EditorTiptap
                :value.sync="data.lesson.contents"/>
            </td>
          </tr>
        </template>
      </template>
    </TableView>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import InfoDosc from '@lemontree-ai/lemontree-admin-common-front/components/common/infoDosc/InfoDosc';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import Textarea from '@lemontree-ai/lemontree-admin-common-front/components/common/textarea/Textarea';
import EditorTiptap from '@lemontree-ai/lemontree-admin-common-front/components/common/editor/EditorTiptap';
import Radio from '@lemontree-ai/lemontree-admin-common-front/components/common/radio/Radio';
import { getSelectDataList } from '@lemontree-ai/lemontree-admin-common-front/utils/selectDataUtils'
import { pageValidated } from '@lemontree-ai/lemontree-admin-common-front/utils/validateUtils'
import ContentFolderDataViewModel from '@/views/worldMap/contentFolder/viewModel/ContentFolderDataViewModel'

export default {
  name:'QuestQuizRegisterPopup',
  components:{
    Popup,
    TableView,
    InfoDosc,
    IconSvg,
    Selectbox,
    Input,
    Textarea,
    EditorTiptap,
    Radio
  },
  props:{
    currentDataViewModel: Object,
    questValidData: Object,
    lessonValidData: Object
  },
  data(){
    return{
      dataViewModel: new ContentFolderDataViewModel(),
    }
  },
  beforeMount(){
    this.dataViewModel.setCurrentData(this.currentDataViewModel);
  },
  computed:{
    data(){
      return this.dataViewModel.QUEST.QUIZ.detailData
    },
    isDisabledCourse(){
      return this.data.id && this.data.status != 'DRAFT'
    },
    isLesson(){
      return this.data.lessonPosition != 'NONE'
    },
    lessonDataList(){
      return getSelectDataList('quest_lesson');
    },
    vaild(){
      let lessonValid = false;
      if( this.data.lessonPosition != 'NONE' ){
        lessonValid = pageValidated(this.lessonValidData, this.data.lesson);
      }
      return pageValidated(this.questValidData, this.data) || lessonValid;
    },
  },
  methods:{
    onUpdatePlace(value){
      if(this.data.placeId != value){
        this.data.placeId = value;
        this.dataViewModel.getCourseList(value);
        this.data.courseId = null;
      }
    },
  }
}
</script>