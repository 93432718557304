var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"groupAnswer",class:[
    'group_answer',
    _vm.groupAnswerType,
    _vm.isActive ? 'active':'disabled'
  ],style:(_vm.cssProps)},_vm._l((_vm.options),function(option,index){return _c('div',{key:`option${index}`,class:[
      'item_answer',
      _vm.selectedAnswerItem.user_answer && ((option === _vm.selectedAnswerItem.quiz_answer) || _vm.isAll) ? 'item_answer_correct' : 'item_answer_wrong',
      _vm.selectedAnswerItem.user_answer ? ( option === _vm.selectedAnswerItem.user_answer  || _vm.isAll ? 'active' : 'disabled' ) : ''
    ]},[_c('button',{class:['btn_answer'],attrs:{"disabled":Boolean(_vm.selectedAnswerItem.user_answer)},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('onClickAnswer',_vm.quizId,option)}}},[(_vm.isOX)?_c('IconSvg',{attrs:{"iconName":option,"icoSize":option === 'O' ? 33 : 30,"iconColor":option === 'O' ? '#579BF5' : '#F98E5A'}}):_c('span',{staticClass:"txt_answer"},[_vm._v(_vm._s(option))])],1),(_vm.selectedAnswerItem.user_answer && (option === _vm.selectedAnswerItem.quiz_answer || _vm.isAll ))?_c('div',{staticClass:"badge_answer"},[_c('div',{staticClass:"txt_badge"},[_vm._v(_vm._s(_vm.$poData.quiz_explanation_answer_badge))])]):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }