export default class CourseModel {
  constructor(){
    this.objRaw = {};
    this.id = null; // 코스 id
    this.placeId = null; // 장소 id
    this.name = ''; // 코스명
    this.description = ''; // 코스설명
    this.image = ''; // 썸네일
    this.dependentCourseId = null; // 선수코스 id
    this.difficulty = 1; // 난도
    this.audience = 'ALL'; // 이용자 구분 - 전체공개 / 부모앱
    this.position = null; // 순서
    this.status = ''; // 상태
    this.historyDataList = []; // 생성,수정 정보
  }
  setData(obj){
    if( obj ) this.objRaw = JSON.parse(JSON.stringify(obj));
    const { id, place, placeId, name, description, image, dependentCourseId, difficulty, audience, position, status, createdAt, createdBy, updatedAt, updatedBy } = obj;
    this.id = id;
    this.placeId = place ? place.id : placeId;
    this.name = name;
    this.description = description;
    this.image = image;
    this.dependentCourseId = dependentCourseId;
    this.difficulty = difficulty;
    this.audience = audience;
    this.position = position;
    this.status = status;
    this.historyDataList = [
      {
        by: createdBy,
        at: createdAt,
        type: '생성'
      },
      {
        by: updatedBy,
        at: updatedAt,
        type: '수정'
      }
    ];
  }
  setCurrentPlaceId(id){
    this.placeId = id
  }
  getData(){
    if(!this.id){
      return this.getRegisterData()
    }else{
      return this.getModifyData()
    }
  }
  getRegisterData(){
    let obj = {
      placeId : this.placeId,
      name : this.name,
      description : this.description,
      image : this.image,
      dependentCourseId : this.dependentCourseId,
      difficulty : this.difficulty,
      audience : this.audience,
    }
    return obj;
  }
  getModifyData(){
    let obj = {
      id : this.id,
      placeId : this.placeId,
      name : this.name,
      description : this.description,
      image : this.image,
      dependentCourseId : this.dependentCourseId,
      difficulty : this.difficulty,
      audience : this.audience,
      status : this.status,
    }
    return obj;
  }
}