<template>
  <div :class="[
    'inner_cont',
    'inner_quiz_cont',
    isActive ? 'active':'disabled',
    { inner_quiz_cont_img : quizContent.content_attachment_list.length > 0 }
  ]">
    <div class="quiz_body">
      <div class="area_img"
        v-if="quizContent.content_attachment_list.length > 0">
        <div class="inner_area_img">
          <img
          class="img_quiz"
          :src="content_attachment"
          onerror="/assets/images/img_empty.png"/>
          <p v-if="quizContent.content_attachment_origin" class="txt_img">출처 : {{ quizContent.content_attachment_origin }}</p>
        </div>
      </div>
      <div :class="['quiz_contents']" v-html="quizContent.contents"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Question',
  props:{
    isActive: {
      type: Boolean,
      default: true,
    },
    quizContent: Object,
  },
  computed:{
    content_attachment(){
      const dataUrl = this.quizContent.content_attachment;
      const uploadedUrl = this.quizContent.content_attachment_list[0].src;
      let result;
      if(uploadedUrl){
        result = uploadedUrl
      }else{
        result = dataUrl || ''
      }
      return result;
    }
  }
}
</script>

<style>
#quizContent .inner_quiz_cont{padding-top:20px}
#quizContent.cont_middle .inner_quiz_cont{display:inline-block;padding:0}
#quizContent.cont_middle .inner_cont.inner_quiz_cont_img{vertical-align:top;}

.quiz_body .area_img{padding:26px 20px 0}
.quiz_body .area_img .inner_area_img{position:relative}
.quiz_body .img_quiz{display:block;width:100%;border-radius: 8px;}
.quiz_body .txt_img{margin-top:4px;
font-size: 12px;
font-weight: 500;
line-height: 16px;
color:#999;
text-align:right;
word-wrap:break-word;word-break:keep-all
}

.quiz_body .quiz_contents{padding:28px 20px;font-weight:500;font-size:20px;line-height:30px;word-wrap:break-word;word-break:keep-all}

/* 높이 640 이하 디바이스 */
.short_device .quiz_body .quiz_contents{font-size:20px;line-height:30px}
</style>