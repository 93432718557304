import CourseModel from '@/views/worldMap/contentFolder/model/course/CourseModel';
import QuestModel from '@/views/worldMap/contentFolder/model/quest/QuestModel'
import LessonModel from '@/views/worldMap/contentFolder/model/quest/LessonModel'
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import { endWord } from '@lemontree-ai/lemontree-admin-common-front/utils/stringUtils'

export default class ContentFolderViewModel {
  constructor() {
    this.dataViewModel = {};

    // this.model = new ContentFolderModel();
    this.courseModel = new CourseModel();
    this.questModel = new QuestModel();
    this.lessonModel = new LessonModel();
    this.groupCategoryDrawData = {
      MAP: {
        title: '지도',
        id: 'MAP',
        index: 0,
        titleList: [
          { title: '상태' },
          { title: '' },
          { title: '지도이름', isFill: true },
          { title: '스타일' },
          { title: '타입' },
        ],
        isDragable: false,
        isHead: true,
        isAddBtn: false,
        isSearchBtn: false,
  
        // event
        contextMenu: [
          { icon:'🗺', text:'지도 정보보기', event:(data) => this.onClickMapDetail(data) },
          // DRAFT
          { icon:'👀', text:'지도 공개하기', viewIf:{ value:'status', condition:'UNREGISTERED' }, event:(data) => this.updateMapStatus('MAP','REGISTERED',data) },
          { icon:'✔️', text:'완료로 전환', viewIf:{ value:'status', condition:'UNREGISTERED' }, event:(data) => this.updateMapStatus('MAP','DONE',data) },
          { icon:'🔒', text:'비공개로 전환', viewIf:{ value:'status', condition:'REGISTERED' }, event:(data) => this.updateMapStatus('MAP','UNREGISTERED',data) },
          { icon:'✔️', text:'완료로 전환', viewIf:{ value:'status', condition:'REGISTERED' }, event:(data) => this.updateMapStatus('MAP','DONE',data) },
          { icon:'👀', text:'지도 공개하기', viewIf:{ value:'status', condition:'DONE' }, event:(data) => this.updateMapStatus('MAP','REGISTERED',data) },
          { icon:'🔒', text:'비공개로 전환', viewIf:{ value:'status', condition:'DONE' }, event:(data) => this.updateMapStatus('MAP','UNREGISTERED',data) },
        ],
        isDetailPopup: false,
        isRegisterPopup: false
      },
      PLACE: {
        title: '장소',
        id: 'PLACE',
        index: 0,
        titleList: [
          { title: 'stage' },
          { title: '장소명', isFill: true },
        ],
        isDragable: false,
        isHead: true,
        isAddBtn:false,
        isSearchBtn:false,
  
        // event
        contextMenu: [
          // DRAFT
          { icon:'✍️', text:'장소 수정', event:(data) => this.onClickPlaceModify(data) },
          { icon:'🗑', text:'장소 삭제', event:(data) => this.onClickPlaceDelete(data) },
        ],
        isRegisterPopup: false
      },
      COURSE: {
        title: '코스',
        id: 'COURSE',
        index: 0,
        titleList: [
          { title:'순서' },
          { title:'상태' },
          { title:'코스명 (퀘스트수)', isFill: true },
          { title:'검토' },
        ],
        isDragable:true,
        isHead: true,
        isAddBtn:true,
        isSearchBtn:false,
  
        // event
        contextMenu: [
          // DRAFT
          { icon:'✍️', text:'코스 드래프트 수정', viewIf:{ value:'status', condition:'DRAFT' }, event:(data) => this.onClickCourseModify(data) },
          { icon:'👀', text:'코스 공개하기', viewIf:{ value:'status', condition:'DRAFT' }, event:(data) => this.updateStatus('COURSE','PUBLIC',data) },
          { icon:'🗑', text:'코스 드래프트 삭제', viewIf:{ value:'status', condition:'DRAFT' }, event:(data) => this.onClickCourseDelete(data) },
          // PUBLIC
          { icon:'✍️', text:'공개중인 코스 수정', viewIf:{ value:'status', condition:'PUBLIC' }, event:(data) => this.onClickCourseModify(data) },
          // { icon:'🔒', text:'비공개로 전환', viewIf:{ value:'status', condition:'PUBLIC' }, event:(data) => this.updateStatus('COURSE','CLOSED',data) },
        ],
        isRegisterPopup: false
      },
      QUEST: {
        QUIZ:{
          title: '퀴즈 퀘스트',
          id: 'QUEST',
          index: 1,
          titleList: [
            { title:'순서' },
            { title:'퀘스트명 (퀴즈수)', isFill: true },
            { title:'검토' },
          ],
          isDragable:true,
          isHead: true,
          isAddBtn:true,
          isSearchBtn:false,
  
          // event
          contextMenu: [
            // DRAFT
            { icon:'✍️', text:'퀘스트 드래프트 수정', viewIf:{ value:'status', condition:'DRAFT' }, event:(data) => this.onClickQuestModify(data) },
            { icon:'🗑', text:'퀘스트 드래프트 삭제', viewIf:{ value:'status', condition:'DRAFT' }, event:(data) => this.onClickQuestDelete(data) },
            // PUBLIC
            { icon:'👀', text:'공개중인 퀘스트 수정', viewIf:{ value:'status', condition:'PUBLIC' }, event:(data) => this.onClickQuestModify(data) },
            // { icon:'🔒', text:'비공개로 전환', viewIf:{ value:'status', condition:'PUBLIC' }, event:(data) => this.updateStatus('QUEST','CLOSED',data) },
          ],
          isRegisterPopup: false
        },
        MISSION:{
          title: '미션 퀘스트',
          id: 'QUEST',
          index: 1,
          titleList: [
            { title:'퀘스트명 (미션수)', isFill: true },
            { title:'검토' },
          ],
          isDragable:false,
          isHead: true,
          isAddBtn:true,
          isSearchBtn:false,
  
          // event
          contextMenu: [
            // DRAFT
            { icon:'✍️', text:'퀘스트 드래프트 수정', viewIf:{ value:'status', condition:'DRAFT' }, event:(data) => this.onClickQuestModify(data) },
            { icon:'🗑', text:'퀘스트 드래프트 삭제', viewIf:{ value:'status', condition:'DRAFT' }, event:(data) => this.onClickQuestDelete(data) },
            // PUBLIC
            { icon:'👀', text:'공개중인 퀘스트 수정', viewIf:{ value:'status', condition:'PUBLIC' }, event:(data) => this.onClickQuestModify(data) },
            // { icon:'🔒', text:'비공개로 전환', viewIf:{ value:'status', condition:'PUBLIC' }, event:(data) => this.updateStatus('QUEST','CLOSED',data) },
          ],
          isRegisterPopup: false
        }
      },
      SUBJECT: {
        QUIZ: {
          title: '퀴즈',
          id: 'SUBJECT',
          index: 2,
          titleList: [
            { title:'순서' },
            { title:'문항', isFill: true },
            { title:'타입' },
            { title:'검수' },
          ],
          isDragable:true,
          isHead: true,
          isAddBtn:true,
          isSearchBtn:false,
          limitCount:5,
          countStatus:{},
          checkStatus:{},
    
          // event
          contextMenu: [
            // DRAFT
            { icon:'🗑', text:`퀴즈 드래프트 삭제`, viewIf:{ value:'status', condition:'DRAFT' }, event:(data) => this.onClickQuizDelete(data) },
          ],
          isRegisterPopup: false
        },
        MISSION: {
          title: '미션',
          id: 'SUBJECT',
          index: 2,
          titleList: [
            { title:'문항', isFill: true },
            { title:'타입' },
            { title:'검수' },
          ],
          isDragable:true,
          isHead: true,
          isAddBtn:true,
          isSearchBtn:false,
          limitCount:1,
          countStatus:{},
          checkStatus:{},
    
          // event
          contextMenu: [
            // DRAFT
            { icon:'🗑', text:`미션 콘텐츠 드래프트 삭제`, viewIf:{ value:'status', condition:'DRAFT' }, event:(data) => this.onClickContentDelete(data) },
          ],
          isRegisterPopup: false
        }
      }
    }
    // valid
    this.validData = {
      MAP: {
        status:{
          value: 'status',
          valueType:'',
          isValid: false,
        },
      },
      PLACE: {
        stage: {
          value: 'stage',
          valueType: 'number',
          isValid: false,
          validAlertDesc: '숫자로만 입력해주세요',
        },
        name: {
          value: 'name',
          valueType: 'korean',
          isValid: false,
          validAlertDesc: '한글으로만 입력해주세요',
        },
        engName: {
          value: 'engName',
          valueType: 'eng',
          isValid: false,
          validAlertDesc: '영문으로만 입력해주세요',
        },
      },
      COURSE: {
        placeId: {
          value: 'placeId',
          valueType: '',
          isValid: false,
        },
        name: {
          value: 'name',
          valueType: '',
          isValid: false,
        },
        description: {
          value: 'description',
          valueType: '',
          isValid: false,
        },
        name: {
          value: 'name',
          valueType: '',
          isValid: false,
        },
        audience: {
          value: 'audience',
          valueType: '',
          isValid: false,
        },
        difficulty: {
          value: 'difficulty',
          valueType: '',
          isValid: false,
        },
      },
      LESSON: {
        title: {
          value: 'title',
          valueType: '',
          isValid: false,
        },
        subTitle: {
          value: 'subTitle',
          valueType: '',
          isValid: false,
        },
        contents: {
          value: 'contents',
          valueType: '',
          isValid: false,
        },
      },
      QUEST: {
        courseId: {
          value: 'courseId',
          valueType: '',
          isValid: false,
        },
        name: {
          value: 'name',
          valueType: '',
          isValid: false,
        },
      },
      SUBJECT: {
        QUIZ: {
          contents: {
            value: 'contents',
            valueType: '',
            isValid: false,
          },
          answer: {
            value: 'answer',
            valueType: '',
            isValid: false,
          },
          options: {
            value: 'options',
            valueType: 'options',
            isValid: false,
          },
          comments: {
            value: 'comments',
            valueType: '',
            isValid: false,
          },
          grade: {
            value: 'grade',
            valueType: '',
            isValid: false,
          },
        },
        MISSION: {
          guideTitle: {
            value: 'guideTitle',
            valueType: '',
            isValid: false,
          },
          guideContents: {
            value: 'guideContents',
            valueType: '',
            isValid: false,
          },
          grade: {
            value: 'grade',
            valueType: '',
            isValid: false,
          },
        }
      }
    }
    // --- quest ---
    // quest - 리스트 상위 탭
    this.tabData = {
      dataList: [
        { id: 'QUIZ', text: '퀴즈', count: 0 },
        { id: 'MISSION', text: '미션', count: 0 },
      ],
      value: 'QUIZ'
    }
    // --- subject ---
    // subject - 문항수 상태
    this.subjectCountStatus = {
      OVER: {
        className: 'item_red',
        desc: '초과',
        iconName: 'cross',
        iconColor: '#FEF4F4',
        iconDarkColor: '#251B1B',
        bgcolor: '#F04848',
      },
      UNDER: {
        className: 'item_bule',
        desc: '미달',
        iconName: 'cross',
        iconColor: '#F3F4FA',
        iconDarkColor: '#1A1B21',
        bgcolor: '#3F51B4',
      },
      DEFAULT: {
        className: 'item_green',
        desc: '적절',
        iconName: 'check',
        iconColor: '#F3F8F3',
        iconDarkColor: '#1A1F1A',
        bgcolor: '#388E3B',
      },
    }
    // subject - 검수 상태
    this.subjectCheckStatus = {
      CLEAR: {
        className: 'item_green',
        desc: '검수완료',
        iconName: 'check',
        iconColor: '#F3F8F3',
        iconDarkColor: '#1A1F1A',
        bgcolor: '#388E3B',
      },
      UNCLEAR: {
        className: 'item_red',
        desc: '미검수 존재',
        iconName: 'cross',
        iconColor: '#FEF4F4',
        iconDarkColor: '#251B1B',
        bgcolor: '#F04848',
      },
    }
  }

  init(dataViewModel){
    this.dataViewModel = dataViewModel;
    // 지도정보 가져오기
    this.dataViewModel.getMapList();
  }
  onClickCopy(linkData,targetName){
    if(targetName){
      targetName = endWord(targetName,'SUBJECT')
    }
    window.navigator.clipboard.writeText(linkData).then(() => {
      store.dispatch('commonToast/fetchToastStart', `${targetName} 복사되었습니다`);
    });
  }
  // ===== EVENT =====

  // --- common ---
  // 리스트 추가
  onClickAdd(categoryId){
    switch(categoryId){
      case 'PLACE': {
        this.groupCategoryDrawData.PLACE.isRegisterPopup = true;
        break
      }
      case 'COURSE': {
        this.groupCategoryDrawData.COURSE.isRegisterPopup = true;
        break
      }
      case 'QUEST': {
        this.groupCategoryDrawData.QUEST[this.dataViewModel.QUEST.type.value].isRegisterPopup = true;
        break
      }
      case 'SUBJECT': {
        this.groupCategoryDrawData.SUBJECT[this.dataViewModel.QUEST.type.value].isRegisterPopup = true;
        break
      }
    }
  }
  // 리스트 검색
  onClickSearch(categoryId,searchData){
    console.log('onClickSearch',categoryId,searchData)
  }
  // 항목 선택
  onSelectCategoryItem(categoryId,rowData){
    let beforeValue = null;
    let setValueEvent = undefined;
    let getDataEvent = undefined;

    switch(categoryId){
      case 'MAP': {
        beforeValue = this.dataViewModel.MAP.value;
        setValueEvent = () => {
          this.dataViewModel.MAP.value = rowData.id;
        };
        getDataEvent = () => {
          this.dataViewModel.getPlaceList(rowData.id)
        };
        break
      }
      case 'PLACE': {
        beforeValue = this.dataViewModel.PLACE.value;
        setValueEvent = () => {
          this.dataViewModel.PLACE.value = rowData.id;
        };
        getDataEvent = () => {
          this.dataViewModel.getCourseList(rowData.id)
        };
        rowData = { id: rowData.id };
        break
      }
      case 'COURSE': {
        beforeValue = this.dataViewModel.COURSE.value;
        setValueEvent = () => {
          this.dataViewModel.COURSE.value = rowData.id;
        };
        getDataEvent = () => {
          this.dataViewModel.getQuestList(rowData.id)
        };
        break
      }
      case 'QUEST': {
        beforeValue = this.dataViewModel.QUEST[this.dataViewModel.QUEST.type.value].value;
        setValueEvent = () => {
          this.dataViewModel.QUEST[this.dataViewModel.QUEST.type.value].value = rowData.id;
        };
        getDataEvent = () => {
          this.dataViewModel.getSubjectList(rowData.id)
        };
        break
      }
      case 'SUBJECT': {
        beforeValue = this.dataViewModel.SUBJECT[this.dataViewModel.QUEST.type.value].value;
        setValueEvent = () => {
          this.dataViewModel.SUBJECT[this.dataViewModel.QUEST.type.value].value = rowData.id;
        };
        getDataEvent = () => {
          const callBack = () => {
            this.groupCategoryDrawData.SUBJECT[this.dataViewModel.QUEST.type.value].isRegisterPopup = true;
          }
          this.dataViewModel.getSubjectData(rowData.id,callBack);
          return;
        };
        break
      }
    }
    if(beforeValue != rowData.id){
      setValueEvent();
      getDataEvent();
    }else if(categoryId === 'SUBJECT'){
      setValueEvent();
      getDataEvent();
    }
  }
  // 팝업 닫기
  onClickPopupClose(categoryId){
    switch(categoryId){
      case 'MAP': {
        this.groupCategoryDrawData.MAP.isDetailPopup = false;
        this.groupCategoryDrawData.MAP.isRegisterPopup = false;
        this.dataViewModel.resetDetilModel('MAP');
        break
      }
      case 'PLACE': {
        this.groupCategoryDrawData.PLACE.isRegisterPopup = false;
        this.dataViewModel.resetDetilModel('PLACE');
        break
      }
      case 'COURSE': {
        this.groupCategoryDrawData.COURSE.isRegisterPopup = false;
        this.dataViewModel.resetDetilModel('COURSE');
        break
      }
      case 'QUEST': {
        this.groupCategoryDrawData.QUEST[this.dataViewModel.QUEST.type.value].isRegisterPopup = false;
        this.dataViewModel.resetDetilModel('QUEST');
        break
      }
      case 'SUBJECT': {
        this.groupCategoryDrawData.SUBJECT[this.dataViewModel.QUEST.type.value].isRegisterPopup = false;
        this.dataViewModel.resetDetilModel('SUBJECT');
        break
      }
    }
  }
  // 팝업 완료
  onClickPopupComplete(categoryId,data){
    switch(categoryId){
      case 'MAP': {
        const isModify = Boolean(this.dataViewModel[categoryId].detailData.id);
        const callBack = () => {
          this.groupCategoryDrawData.MAP.isDetailPopup = false;
        }
        if(isModify){
          this.dataViewModel.putMap(data, callBack);
        }
        break
      }
      case 'PLACE': {
        const isModify = Boolean(this.dataViewModel[categoryId].detailData.id);
        const callBack = () => {
          this.groupCategoryDrawData.PLACE.isRegisterPopup = false;
        }
        if(isModify){
          this.dataViewModel.putPlaceData(data, callBack);
        }else{
          this.dataViewModel.postPlaceData(data, callBack);
        }
        break
      }
      case 'COURSE': {
        const isModify = Boolean(this.dataViewModel[categoryId].detailData.id);
        const callBack = () => {
          this.groupCategoryDrawData.COURSE.isRegisterPopup = false;
        }
        if(isModify){
          this.dataViewModel.putCourseData(data, callBack);
        }else{
          this.dataViewModel.postCourseData(data, callBack);
        }
        break
      }
      case 'QUEST': {
        const isModify = Boolean(this.dataViewModel[categoryId][this.dataViewModel.QUEST.type.value].detailData.id);
        const callBack = () => {
          this.groupCategoryDrawData.QUEST[this.dataViewModel.QUEST.type.value].isRegisterPopup = false;
        }
        if(isModify){
          this.dataViewModel.putQuestData(data, callBack);
        }else{
          this.dataViewModel.postQuestData(data, callBack);
        }
        break
      }
      case 'SUBJECT': {
        const id = this.dataViewModel[categoryId][this.dataViewModel.QUEST.type.value].detailData.id
        const isModify = Boolean(id);
        const callBack = () => {
          this.groupCategoryDrawData.SUBJECT[this.dataViewModel.QUEST.type.value].isRegisterPopup = false;
        }
        if(isModify){
          this.dataViewModel.putSubjectData(data, callBack, id);
        }else{
          this.dataViewModel.postSubjectData(data, callBack);
        }
        break
      }
    }
  }
  // 순서변경
  onModifyOrder(orderData){
    switch(orderData.type){
      case 'COURSE': {
        this.dataViewModel.putCoursePositionData(orderData)
        break
      }
      case 'QUEST': {
        this.dataViewModel.putQuestPositionData(orderData);
        break
      }
      case 'QUIZ': {
        this.dataViewModel.putSubjectPositionData(orderData);
        break
      }
    }
  }
  // 상태값 업데이트
  updateStatus(categoryId,status,data){
    console.log('updateStatus',categoryId,status,data)
  }
  updateMapStatus(categoryId,status,data){
    switch(categoryId){
      case 'MAP': {
        this.dataViewModel.putMapStatus(data,status);
      }
    }
  }
  // --- map ---
  // map - 상세
  onClickMapDetail(data){
    // const callBack = () => {
    //   this.groupCategoryDrawData.MAP.isDetailPopup = true;
    // }
    this.dataViewModel.MAP.detailData.setData(data);
    this.groupCategoryDrawData.MAP.isDetailPopup = true;
  }
  // --- place ---
  // place - 수정
  onClickPlaceModify(data){
    const callBack = () => {
      this.groupCategoryDrawData.PLACE.isRegisterPopup = true;
      const result = this.dataViewModel.PLACE.detailData;
    }
    this.dataViewModel.getPlaceData(data,callBack);
  }
  // place - 삭제
  onClickPlaceDelete(data){
    const requestDelete = () => {
      this.dataViewModel.deletePlaceData(data.id);
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `장소를 정말 삭제하시겠습니까?`,
      closeBtnText: '취소',
      compeleteBtnText: '확인',
      confirmCallBack: requestDelete,
    });
  }
  // --- course ---
  // course - 수정
  onClickCourseModify(data){
    const callBack = () => {
      this.groupCategoryDrawData.COURSE.isRegisterPopup = true;
    }
    this.dataViewModel.getCourseData(data.id,callBack);
  }
  // course - 삭제
  onClickCourseDelete(data){
    const requestDelete = () => {
      this.dataViewModel.deleteCourseData(data.id);
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `코스를 정말 삭제하시겠습니까?`,
      closeBtnText: '취소',
      compeleteBtnText: '확인',
      confirmCallBack: requestDelete,
    });
  }

  // --- quest ---
  setQuestList(data){
    this.tabData.dataList.forEach(tabItem => {
      const dataFilter = data.filter(item => item.quizType === tabItem.id)
      this.model.QUEST[tabItem.id].dataList = dataFilter;
      this.model.QUEST[tabItem.id].value = null;
      this.model.QUEST[tabItem.id].paginationData.totalCount = dataFilter.length;
      tabItem.count = dataFilter.length;
    });
  }
  // quest - 수정
  onClickQuestModify(data){
    const callBack = () => {
      this.groupCategoryDrawData.QUEST[this.dataViewModel.QUEST.type.value].isRegisterPopup = true;
    }
    this.dataViewModel.getQuestData(data.id,callBack);
  }
  // quest - 삭제
  onClickQuestDelete(data){
    const requestDelete = () => {
      this.dataViewModel.deleteQuestData(data.id);
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `퀘스트를 정말 삭제하시겠습니까?`,
      closeBtnText: '취소',
      compeleteBtnText: '확인',
      confirmCallBack: requestDelete,
    });
  }
  // quest - tab 선택
  onClickTab(tabId){
    this.dataViewModel.QUEST[this.dataViewModel.QUEST.type.value].value = null;
    this.dataViewModel.QUEST.type.value = tabId;
  }

  // --- quiz ---
  // quiz - 삭제
  onClickQuizDelete(data){
    const requestDelete = () => {
      this.dataViewModel.deleteSubjectData(data.id);
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `퀴즈를 정말 삭제하시겠습니까?`,
      closeBtnText: '취소',
      compeleteBtnText: '확인',
      confirmCallBack: requestDelete,
    });
  }
  // quiz - 문항수 상태 가져오기
  getQuizCountStatus(total, limit){
    if(total > limit){
      return this.subjectCountStatus.OVER
    }else if(total < limit){
      return this.subjectCountStatus.UNDER
    }else{
      return this.subjectCountStatus.DEFAULT
    }
  }
  // quiz - 검수 상태 가져오기
  getQuizCheckStatus(dataList){
    const isCheckClear = !dataList.find(item => !item.checked)
    if(isCheckClear){
      return this.subjectCheckStatus.CLEAR
    }else{
      return this.subjectCheckStatus.UNCLEAR
    }
  }
}