<template>
  <PageWithLayout :isBodyFull="true">
    <div class="group_category">
      <GroupCategory
        :groupCategoryData.sync="viewModel.groupCategoryDrawData.MAP"
        :dataList.sync="viewModel.dataViewModel.MAP.dataList"
        :value.sync="viewModel.dataViewModel.MAP.value"
        @onClickCategoryItem="(categoryId,rowData) => viewModel.onSelectCategoryItem(categoryId,rowData)">
          <template v-slot:item_category="slotProps">
            <span class="txt_item">
              <span class="box_color" :style="`background-color:${ $options.filters.convertIdToColor(slotProps.itemData.status, 'map_status_hex') }`"></span>
            </span>
            <strong class="txt_count">
              <img class="img_thumnail" :src="slotProps.itemData.thumbnailImageUrl" alt="">
            </strong>
            <strong class="tit_item fill_item">{{ slotProps.itemData.name }}</strong>
            <span class="txt_count txt_map_info">{{ slotProps.itemData.mapStyle.slice(0,4) }}</span>
            <span class="txt_count txt_map_info">{{ slotProps.itemData.mapType.slice(0,4) }}</span>
          </template>
      </GroupCategory>
      <GroupCategory
        :groupCategoryData.sync="viewModel.groupCategoryDrawData.PLACE"
        :dataList.sync="viewModel.dataViewModel.PLACE.dataList"
        :value.sync="viewModel.dataViewModel.PLACE.value"
        :upperData="{
          title : '지도',
          value : viewModel.dataViewModel.MAP.value,
        }"
        @onClickAdd="categoryId => viewModel.onClickAdd(categoryId)"
        @onClickCategoryItem="(categoryId,rowData) => viewModel.onSelectCategoryItem(categoryId,rowData)">
        <!-- <template v-slot:top_category>
          <div class="area_select">
            <Selectbox
              :dataList="viewModel.dataViewModel.MAP.dataList"
              :value="viewModel.dataViewModel.MAP.value"
              :showLine="9"
              @update:value="value => viewModel.onSelectCategoryItem('MAP',value)"/>
          </div>
        </template> -->
          <template v-slot:item_category="slotProps">
            <span class="txt_item">{{ slotProps.itemData.stage }}</span>
            <strong class="tit_item">{{ slotProps.itemData.text }}</strong>
          </template>
      </GroupCategory>
      <GroupCategory
        :groupCategoryData.sync="viewModel.groupCategoryDrawData.COURSE"
        :dataList.sync="viewModel.dataViewModel.COURSE.dataList"
        :value.sync="viewModel.dataViewModel.COURSE.value"
        :upperData="{
          title : '장소',
          value : viewModel.dataViewModel.PLACE.value,
        }"
        @onClickAdd="categoryId => viewModel.onClickAdd(categoryId)"
        @onClickSearch="(categoryId,searchData) => viewModel.onClickSearch(categoryId,searchData)"
        @onClickCategoryItem="(categoryId,rowData) => viewModel.onSelectCategoryItem(categoryId,rowData)"
        @onModifyOrder="orderData => viewModel.onModifyOrder(orderData)">
        <!-- <template v-slot:top_category>
          <div class="area_select">
            <Selectbox
              :dataList="viewModel.dataViewModel.PLACE.dataList"
              :value="viewModel.dataViewModel.PLACE.value"
              :showLine="9"
              @update:value="value => viewModel.onSelectCategoryItem('PLACE',value)"/>
          </div>
        </template> -->
        <template v-slot:item_category="slotProps">
          <span class="txt_item">
            <IconSvg iconName="drag" :size="18" iconColor="#bbb" />
          </span>
          <span class="txt_item">
            <span class="box_color" :style="`background-color:${ $options.filters.convertIdToColor(slotProps.itemData.status, 'contents_status_hex') }`"></span>
          </span>
          <span v-if="viewModel.selectPlaceId === 'All'" class="txt_item">[{{ slotProps.itemData.place.id | convertIdToText(viewModel.contentsData.PLACE.dataList) }}]</span>
          <strong class="tit_item">{{ slotProps.itemData.name }}</strong>
          <span class="txt_count fill_item">({{ slotProps.itemData.quizCount + slotProps.itemData.missionCount }})</span>
          <span class="txt_item">{{!slotProps.itemData.checked ? '⚠️': ''}}</span>
        </template>
      </GroupCategory>
      <GroupCategory
        :groupCategoryData.sync="viewModel.groupCategoryDrawData.QUEST[questType]"
        :dataList.sync="viewModel.dataViewModel.QUEST[questType].dataList"
        :value.sync="viewModel.dataViewModel.QUEST[questType].value"
        :upperData="{
          title : viewModel.groupCategoryDrawData.COURSE.title,
          value : viewModel.dataViewModel.COURSE.value,
        }"
        @onClickAdd="categoryId => viewModel.onClickAdd(categoryId)"
        @onClickSearch="(categoryId,searchData) => viewModel.onClickSearch(categoryId,searchData)"
        @onClickCategoryItem="(categoryId,rowData) => viewModel.onSelectCategoryItem(categoryId,rowData)"
        @onModifyOrder="orderData => viewModel.onModifyOrder(orderData)">
          <!-- <template v-slot:top_category>
            <div class="tab_comm">
              <button
                v-for="tab in viewModel.dataViewModel.QUEST.type.dataList"
                :style="{width:100/viewModel.dataViewModel.QUEST.type.dataList.length+'%'}"
                :key="`tab${tab.id}`"
                :class="['btn_tab', { active : tab.id === viewModel.dataViewModel.QUEST.type.value }]"
                @click.stop="viewModel.onClickTab(tab.id)">{{ tab.text }}<span class="count_tab">({{ tab.count || 0 }})</span></button>
            </div>
          </template> -->
          <template v-slot:item_category="slotProps">
              <IconSvg iconName="drag" :size="18" iconColor="#bbb" />
            <strong class="tit_item">{{ slotProps.itemData.name }}</strong>
            <span class="txt_count fill_item">({{ slotProps.itemData.quizCount }})</span>
            <span class="txt_item">{{!slotProps.itemData.checked ? '⚠️': ''}}</span>
          </template>
      </GroupCategory>
      <GroupCategory
        :groupCategoryData.sync="viewModel.groupCategoryDrawData.SUBJECT[questType]"
        :dataList.sync="viewModel.dataViewModel.SUBJECT[questType].dataList"
        :value.sync="viewModel.dataViewModel.SUBJECT[questType].value"
        :upperData="{
          title : viewModel.groupCategoryDrawData.QUEST[questType].title,
          value : viewModel.dataViewModel.QUEST[questType].value,
        }"
        @onClickAdd="categoryId => viewModel.onClickAdd(categoryId)"
        @onClickSearch="(categoryId,searchData) => viewModel.onClickSearch(categoryId,searchData)"
        @onClickCategoryItem="(categoryId,rowData) => viewModel.onSelectCategoryItem(categoryId,rowData)"
        @onModifyOrder="orderData => viewModel.onModifyOrder(orderData)">
        <template v-slot:top_category>
          <div class="area_status">
            <dl :class="['item_status', countStatus.className ]">
              <dt>문항수</dt>
              <dd>
                <IconSvg
                  :iconName="countStatus.iconName"
                  :size="7"
                  :iconColor="countStatus.iconColor"
                  :iconDarkColor="countStatus.iconDarkColor"
                  :bgcolor="countStatus.bgcolor"
                />{{ countStatus.desc }} ({{ subjectModel.paginationData.totalCount }}/{{ viewModel.groupCategoryDrawData.SUBJECT[questType].limitCount }})
              </dd>
            </dl>
            <dl :class="['item_status', checkStatus.className ]">
              <dt>검수상태</dt>
              <dd>
                <IconSvg
                  :iconName="checkStatus.iconName"
                  :size="7"
                  :iconColor="checkStatus.iconColor"
                  :iconDarkColor="checkStatus.iconDarkColor"
                  :bgcolor="checkStatus.bgcolor"
                />{{ checkStatus.desc }}
              </dd>
            </dl>
          </div>
        </template>
        <template v-slot:item_category="slotProps">
          <template v-if="questType === 'QUIZ'">
            <span class="txt_item">
              <IconSvg iconName="drag" :size="18" iconColor="#bbb" />
            </span>
            <strong class="tit_item fill_item">{{ slotProps.itemData.quizContents.contents | removeHtmlTag }}</strong>
            <span class="txt_item"><IconSvg :iconName="slotProps.itemData.quizContentType | wordToLowerCase" :size="18" :iconColor="slotProps.itemData.quizContentType | convertIdToColor('quiz_quizContentType')" /></span>
            <span class="txt_item txt_receipt">
              <IconSvg v-if="slotProps.itemData.checked" iconName="check" :size="10" iconColor="#fff" bgcolor="#388E3B" />
              <IconSvg v-else iconName="cross" :size="8" iconColor="#fff" bgcolor="#F04848" />
            </span>
          </template>
          <template v-if="questType === 'MISSION'">
            <strong class="tit_item fill_item">{{ slotProps.itemData.quizContents.guideTitle }}</strong>
            <span class="txt_item"><IconSvg :iconName="slotProps.itemData.quizContentType | wordToLowerCase" :size="18" :iconColor="slotProps.itemData.quizContentType | convertIdToColor('mission_quizContentType')" /></span>
            <span class="txt_item txt_receipt">
              <IconSvg v-if="slotProps.itemData.checked" iconName="check" :size="10" iconColor="#fff" bgcolor="#388E3B" />
              <IconSvg v-else iconName="cross" :size="8" iconColor="#fff" bgcolor="#F04848" />
            </span>
          </template>
        </template>
      </GroupCategory>
    </div>
    <template v-slot:popup>
      <MapDetailPopup
        v-if="viewModel.groupCategoryDrawData.MAP.isDetailPopup"
        :viewModel="viewModel"
        :currentDataViewModel="viewModel.dataViewModel"
        @onClickPopupClose="viewModel.onClickPopupClose('MAP')"
        @onClickPopupComplete="data => viewModel.onClickPopupComplete('MAP',data)"/>
      <PlaceRegisterPopup
        v-if="viewModel.groupCategoryDrawData.PLACE.isRegisterPopup"
        :currentDataViewModel="viewModel.dataViewModel"
        :validData="viewModel.validData.PLACE"
        @onClickPopupClose="viewModel.onClickPopupClose('PLACE')"
        @onClickPopupComplete="data => viewModel.onClickPopupComplete('PLACE',data)"/>
      <CourseRegisterPopup
        v-if="viewModel.groupCategoryDrawData.COURSE.isRegisterPopup"
        :currentDataViewModel="viewModel.dataViewModel"
        :validData="viewModel.validData.COURSE"
        @onClickPopupClose="viewModel.onClickPopupClose('COURSE')"
        @onClickPopupComplete="data => viewModel.onClickPopupComplete('COURSE',data)" />
      <QuestQuizRegisterPopup
        v-if="viewModel.groupCategoryDrawData.QUEST.QUIZ.isRegisterPopup"
        :currentDataViewModel="viewModel.dataViewModel"
        :questValidData="viewModel.validData.QUEST"
        :lessonValidData="viewModel.validData.LESSON"
        @onClickPopupClose="viewModel.onClickPopupClose('QUEST')"
        @onClickPopupComplete="data => viewModel.onClickPopupComplete('QUEST',data)"/>
      <QuestMissionRegisterPopup
        v-if="viewModel.groupCategoryDrawData.QUEST.MISSION.isRegisterPopup"
        :currentDataViewModel="viewModel.dataViewModel"
        :questValidData="viewModel.validData.QUEST"
        :lessonValidData="viewModel.validData.LESSON"
        @onClickPopupClose="viewModel.onClickPopupClose('QUEST')"
        @onClickPopupComplete="data => viewModel.onClickPopupComplete('QUEST',data)"/>
      <SubjectQuizRegisterPopup
        v-if="viewModel.groupCategoryDrawData.SUBJECT.QUIZ.isRegisterPopup"
        :currentDataViewModel="viewModel.dataViewModel"
        :validData="viewModel.validData.SUBJECT.QUIZ"
        @onClickPopupClose="viewModel.onClickPopupClose('SUBJECT')"
        @onClickPopupComplete="data => viewModel.onClickPopupComplete('SUBJECT',data)"/>
      <SubjectMissionRegisterPopup
        v-if="viewModel.groupCategoryDrawData.SUBJECT.MISSION.isRegisterPopup"
        :currentDataViewModel="viewModel.dataViewModel"
        :validData="viewModel.validData.SUBJECT.MISSION"
        @onClickPopupClose="viewModel.onClickPopupClose('SUBJECT')"
        @onClickPopupComplete="data => viewModel.onClickPopupComplete('SUBJECT',data)"/>
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import GroupCategory from '@lemontree-ai/lemontree-admin-common-front/components/common/category/GroupCategory'
// import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';

import MapDetailPopup from '@/views/worldMap/contentFolder/view/popup/MapDetailPopup';
import PlaceRegisterPopup from '@/views/worldMap/contentFolder/view/popup/PlaceRegisterPopup';
import CourseRegisterPopup from '@/views/worldMap/contentFolder/view/popup/CourseRegisterPopup';
import QuestQuizRegisterPopup from '@/views/worldMap/contentFolder/view/popup/QuestQuizRegisterPopup';
import QuestMissionRegisterPopup from '@/views/worldMap/contentFolder/view/popup/QuestMissionRegisterPopup';
import SubjectQuizRegisterPopup from '@/views/worldMap/contentFolder/view/popup/SubjectQuizRegisterPopup';
import SubjectMissionRegisterPopup from '@/views/worldMap/contentFolder/view/popup/SubjectMissionRegisterPopup';
// viewModel
import ContentFolderViewModel from '@/views/worldMap/contentFolder/viewModel/ContentFolderViewModel'
import ContentFolderDataViewModel from '@/views/worldMap/contentFolder/viewModel/ContentFolderDataViewModel'

export default {
  name:'ContentFolder',
  components:{
    PageWithLayout,
    GroupCategory,
    // Selectbox,
    IconSvg,

    MapDetailPopup,
    PlaceRegisterPopup,
    CourseRegisterPopup,
    QuestQuizRegisterPopup,
    QuestMissionRegisterPopup,
    SubjectQuizRegisterPopup,
    SubjectMissionRegisterPopup
  },
  beforeMount(){
    this.viewModel.init(this.dataViewModel);
  },
  computed:{
    questType(){
      return this.viewModel.dataViewModel.QUEST.type.value;
    },
    questModel(){
      return this.viewModel.dataViewModel.QUEST[this.questType]
    },
    subjectModel(){
      return this.viewModel.dataViewModel.SUBJECT[this.questType]
    },
    countStatus(){
      return this.viewModel.getQuizCountStatus(this.subjectModel.paginationData.totalCount,this.viewModel.groupCategoryDrawData.SUBJECT[this.questType].limitCount)
    },
    checkStatus(){
      return this.viewModel.getQuizCheckStatus(this.subjectModel.dataList)
    }
  },
  data(){
    return{
      viewModel: new ContentFolderViewModel(),
      dataViewModel: new ContentFolderDataViewModel(),
    }
  },
}
</script>
<style scoped>
.search_comm{flex:0 0 37px;margin-bottom:20px}
.group_category{flex:1 1 100%;height:calc(100% + 20px);overflow:hidden;overflow-x:auto;white-space:nowrap}
.group_category .area_category{width:20%}
.txt_map_info{letter-spacing:-0.05em}
.img_thumnail{display:block;width:28px;height:28px;margin:-8px 0;border-radius:4px}
</style>