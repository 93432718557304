<template>
  <Popup
    :title="data.id ? '코스 수정' : '새로운 코스 추가'"
    :maxWidth="800"
    closeBtnText="닫기"
    :isDisabeldCompeleteBtn="vaild"
    compeleteBtnText="저장"
    @onClickClose="$emit('onClickPopupClose')"
    @onClickComplete="$emit('onClickPopupComplete',data.getData())">
    <InfoDosc
      v-if="data.id"
      :status="data.status"
      statusConvertIdToColor="contents_status"
      :dataList="data.historyDataList"/>
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:170px"/>
        <col />
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>MAP 장소선택<IconSvg v-if="!isDisabledPlace" iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <Selectbox
              :isDisabled="isDisabledPlace"
              :dataList="dataViewModel.PLACE.dataList"
              :value.sync="data.placeId"/>
          </td>
        </tr>
        <tr>
          <th>코스명<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <Input
              placeholder="코스명"
              :value.sync="data.name"/>
          </td>
        </tr>
        <tr>
          <th>코스설명<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <Textarea
              placeholder="코스설명"
              :value.sync="data.description"
              :max="100"
              :showLine="4"
              :isCount="true"/>
          </td>
        </tr>
        <tr>
          <th>학습대상<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <Radio
              :dataList="audienceDataList"
              :value.sync="data.audience"/>
          </td>
        </tr>
        <tr>
          <th>코스난도<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <Radio
              :dataList="difficultyDataList"
              :value.sync="data.difficulty"/>
          </td>
        </tr>
      </template>
    </TableView>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import InfoDosc from '@lemontree-ai/lemontree-admin-common-front/components/common/infoDosc/InfoDosc';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import Textarea from '@lemontree-ai/lemontree-admin-common-front/components/common/textarea/Textarea';
import Radio from '@lemontree-ai/lemontree-admin-common-front/components/common/radio/Radio';
import { getSelectDataList } from '@lemontree-ai/lemontree-admin-common-front/utils/selectDataUtils'
import { pageValidated } from '@lemontree-ai/lemontree-admin-common-front/utils/validateUtils'
import ContentFolderDataViewModel from '@/views/worldMap/contentFolder/viewModel/ContentFolderDataViewModel'

export default {
  name:'CourseRegisterPopup',
  components:{
    Popup,
    InfoDosc,
    TableView,
    IconSvg,
    Selectbox,
    Input,
    Textarea,
    Radio
  },
  props:{
    currentDataViewModel: Object,
    // contentFolderData: Object,
    validData: Object
  },
  data(){
    return{
      isModify: false,
      dataViewModel: new ContentFolderDataViewModel(),
    }
  },
  beforeMount(){
    this.dataViewModel.setCurrentData(this.currentDataViewModel);
  },
  computed:{
    data(){
      return this.dataViewModel.COURSE.detailData
    },
    isDisabledPlace(){
      return this.data.id && this.data.status != 'DRAFT'
    },
    vaild(){
      return pageValidated(this.validData, this.data)
    },
    // dependentCourseDataList(){
    //   let result = [
    //     { id:null, name:'없음' }
    //   ];
    //   result = result.concat(this.viewModel.contentsData.COURSE.dataList);
    //   if(this.data.id){
    //     result = result.filter(item => this.data.id != item.id);
    //   }
    //   result = result.map(item => {
    //     let rObj = {};
    //     rObj.id = item.id;
    //     rObj.text = `${item.place ? `[${item.place.name}] `:''}${item.name}`;
    //     return rObj;
    //   });
    //   return result;
    // },
    audienceDataList(){
      return getSelectDataList('course_audience');
    },
    difficultyDataList(){
      return getSelectDataList('course_difficulty');
    }
  }
}
</script>