<template>
  <div class="inner_cont inner_intro_cont">
    <div v-if="quizContent.intro_contents" class="intro_contents" v-html="quizContent.intro_contents"></div>
    <Button
      class="btn_next"
      btnSize="medium"
      btnStyle="primary_white"
      text="다음"
      @onClickBtn="$emit('goNextPage')">
      <template v-slot:btn_right>
        <IconSvg iconName="arrow_right" :size="24" :icoSize="16" iconColor="#fff"/>
      </template>
    </Button>
  </div>
</template>

<script>
import Button from '@/components/common/button/Button';
import IconSvg from '@/components/common/icon/IconSvg';

export default {
  name: 'Intro',
  components:{
    Button,
    IconSvg
  },
  props:{
    paginationData: Object,
    quizContent: Object,
    isTypingIntro: Boolean
  },
}
</script>

<style scoped>
.inner_intro_cont{margin-top:-60px;padding:0 20px}

.tit_intro{display:block;font-weight:700;font-size:24px;line-height:30px;color:#8F69DD}

.intro_contents{margin-top:10px;font-weight:500;font-size:20px;line-height:30px;word-wrap:break-word;word-break:keep-all;white-space:normal}

.btn_next{position:absolute;bottom:48px;right:20px;width:auto;box-shadow:4px 4px 12px rgba(143, 105, 221, 0.64);}
.btn_next .icon_arrow_right{margin:2px 0 0 8px}
</style>