export default class QuizModel {
  constructor(){
    this.introTitle = ''; // 인트로 타이틀
    this.intro_contents = ''; // 인트로 본문
    this.contents = ''; // 문제
    this.content_attachment_list = []; // 문제 첨부자료
    this.content_attachment = ''; // 문제 첨부자료
    this.content_attachment_type = ''; // 문제 첨부자료 타입
    this.content_attachment_origin = ''; // 문제 첨부자료 출처
    this.options = []; // 보기
    this.answer = ''; // 정답
    this.comments = ''; // 해설
    this.grade = 'NORMAL'; // 배점 NORMAL, HIGH
  }
  setData(obj){
    const { id, introTitle, introContents, contents, contentAttachment, contentAttachmentType, contentAttachmentOrigin, options, answer, comments, grade } = obj;
    this.id = id;
    this.introTitle = introTitle;
    this.intro_contents = introContents;
    this.contents = contents;
    if(contentAttachment && contentAttachmentType){
      this.content_attachment_list =[
        {
          src:contentAttachment,
          acceptType:contentAttachmentType
        }
      ]
    }else{
      this.content_attachment_list = [];
    }
    this.content_attachment_origin = contentAttachmentOrigin;
    this.content_attachment_type = contentAttachmentType;
    this.options = options;
    this.answer = answer;
    this.comments = comments;
    this.grade = grade;
  }
  getRegisterData(){
    let content_attachment = '';
    let content_attachment_type = '';
    if(this.content_attachment_list.length > 0){
      const { src, acceptType } = this.content_attachment_list[0];
      content_attachment = src;
      content_attachment_type = acceptType;
    }
    let obj = {
      introTitle : this.introTitle,
      introContents : this.intro_contents,
      contents : this.contents,
      content_attachment : content_attachment,
      content_attachment_type : content_attachment_type,
      content_attachment_origin : this.content_attachment_origin,
      options : this.options,
      answer : this.answer,
      comments : this.comments,
      grade : this.grade,
    }
    return obj;
  }
  getModifyData(){
    let content_attachment = '';
    let contentAttachmentType = this.content_attachment_type;
    if(this.content_attachment_list.length > 0){
      const { src, file } = this.content_attachment_list[0];
      content_attachment = src;
      if(file){
        contentAttachmentType = file.type;
      }
    }
    let obj = {
      introTitle : this.introTitle,
      introContents : this.intro_contents,
      contents : this.contents,
      contentAttachment : content_attachment,
      contentAttachmentType : contentAttachmentType,
      contentAttachmentOrigin : this.content_attachment_origin,
      options : this.options,
      answer : this.answer,
      comments : this.comments,
      grade : this.grade,
    }
    return obj;
  }
}