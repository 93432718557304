<template>
  <div
    :class="['area_preview',{ active: isPreviewShow }]">
    <div class="head_preview">
      <strong class="tit_preview">미리보기</strong>
      <p class="desc_preview">{{ previewPageDesc }}</p>
      <div class="info_device">
        <span class="txt_device">{{ previewDeviceSize.width.replace('px','') }} X {{ previewDeviceSize.height.replace('px','') }}</span>
        <Selectbox
          :dataList="deviceDataList"
          :value.sync="previewDeviceSync"
          @update:value="onUpdateDevice"/>
      </div>
    </div>
    <div
      v-for="deviceItem in deviceDataList"
      :key="deviceItem.id"
      :class="['body_preview', { active : deviceItem.id === previewDevice }]"
      :style="{ width: `${deviceItem.size.width}px`, height: `${deviceItem.size.height}px` }">
      <Swiper
        class="swiper"
        :options="swiperOption"
        :ref="deviceItem.id === previewDevice ? 'previewSwiper':''"
        :key="deviceItem.id"
        @slideChange="slideChange()">
        <SwiperSlide
          v-for="(item, index) in previewDataList"
          :key="`preview${index}`">
          <QuizLayout
            :isShortDevice="deviceItem.size.height <= 640"
            :title="item.quizPageTitle"
            quizPageBg="SECONDARY"
            :paginationData="previewData.paginationData"
            :quizPageMode="item.quizPageMode"
            :quizContent="quizContent"
            :isTypingComment="true"
            :selectedAnswerItem="selectedAnswerItem"
            :selectedAnswerList="selectedAnswerList"
            :ref="deviceItem.id">
            <component
              :is="item.quizPageMode"
              :paginationData="previewData.paginationData"
              :quizContent="quizContent"
              :quizContentType="previewData.quizContentType"
              :selectedAnswerItem="selectedAnswerItem"
              :rewardData="rewardData"
              :isTypingComment="true"/>
            <template v-slot:popup>
            </template>
          </QuizLayout>
        </SwiperSlide>
        <div class="swiper-pagination swiper-product-pagination" slot="pagination"></div>
      </Swiper>
    </div>
    <div v-if="overflowScrollDataList.length > 0" class="foot_preview">
      <p
        v-for="(overflowItem, index) in overflowScrollDataList"
        :key="`descOverflow${index}`"
        class="tc_red desc_overflow"
        @click="$emit('onClickOverflowError',overflowItem)">⚠️ {{ overflowItem.deviceName }} - {{ overflowItem.target }}페이지 스크롤 발생!</p>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide, Pagination } from 'vue-awesome-swiper'
import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';
import QuizLayout from './components/QuizLayout';
import Lesson from './components/Lesson';
import Intro from './components/Intro';
import Question from './components/Question';
import Result from './components/Result';
import Explanation from './components/Explanation';

export default {
  name: 'ContentPreview',
  props:{
    isPreviewShow : Boolean,
    swiperIndex: Number,
    previewData: Object,
    previewDataList: Array,
    deviceDataList: Array,
    previewDevice: String,
    overflowScrollDataList: Array,
  },
  components:{
    Selectbox,
    Swiper,
    SwiperSlide,
    Pagination,
    QuizLayout,
    Lesson,
    Intro,
    Question,
    Result,
    Explanation,
  },
  data(){
    return{
      defaultSize:{ size: { width: 360, height: 740 } },
      isInit: false,
      realIndex:0,
      swiperOption: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 0,
        centeredSlides: false,
        speed:100,
        mousewheel:{
          forceToAxis:true,
        },
        pagination: {
          el: '.swiper-product-pagination',
          type: 'bullets',
          clickable: true
        },
      },
      rewardData: { // 보상정보
        checked_pocket_money : true,
        checked_reward : true,
        success_point: null,
        fail_point: null,
      },
      selectedAnswerList:[
        {
          "checked_answer":true,
        }
      ]
    }
  },
  computed:{
    quizContent(){
      let result = this.previewData.quizContent;
      result.quiz_content_type = this.previewData.quizContentType;
      return result
    },
    selectedAnswerItem(){
      return {
        checked_answer : true,
        quiz_answer : this.quizContent.answer,
        user_answer : this.quizContent.answer,
        comments : this.quizContent.comments
      }
    },
    previewPageDesc(){
      switch(this.realIndex){
        case 0 : { return '인트로' }
        case 1 : { return '문제' }
        // case 2 : { return '보상 및 정답' }
        case 2 : { return '해설' }
      }
    },
    previewDeviceSync:{
      get(){
        this.setSwiperIndex();
        return this.previewDevice;
      },
      set(val){
        this.$emit('update:previewDevice',val);
      }
    },
    previewDeviceSize(){
      let seletedData = this.deviceDataList.find(item => item.id === this.previewDevice) || this.defaultSize;
      const { width, height } =  seletedData.size;
      return { width: `${width}px`, height: `${height}px` }
    },
    isImg(){
      return this.quizContent.content_attachment_list.length > 0
    }
  },
  watch:{
    swiperIndex(){
      this.setSwiperIndex();
    }
  },
  methods:{
    setSwiperIndex(){
      this.$nextTick(() => {
        this.$refs.previewSwiper[0].$swiper.slideTo(this.swiperIndex);
      });
    },
    slideChange(){
      this.realIndex = this.$refs.previewSwiper[0].$swiper.realIndex;
      this.$emit('update:swiperIndex',this.realIndex)
    },
    onUpdateDevice(){
      this.realIndex = 0;
    },
  }
}
</script>

<style scoped>
.swiper::v-deep .swiper-wrapper{width:1440px;height:100%;}
.swiper-slide{position:relative;float:left;height:100%;}
.area_preview{position:absolute;top:67px;right:24px;bottom:70px;overflow:hidden;overflow-y:auto;width:412px;padding-right:20px}

.head_preview{overflow:hidden;padding-top:20px;line-height:20px}
.head_preview .tit_preview{display:inline-block;font-weight:800;font-size:18px;vertical-align:top}
.head_preview .desc_preview{display:inline-block;margin-left:10px;font-size:16px;vertical-align:top}
.head_preview .info_device{overflow:hidden}
.head_preview .info_device .txt_device{float:left;font-size:16px;line-height:38px}
.head_preview .info_device .selectbox_comm{float:right}

.body_preview{position:absolute;top:0;opacity:0;margin:20px auto 0;z-index:-1}
.body_preview.active{position:static;opacity:1;z-index:0}
.swiper-container{overflow:hidden;width:100%;height:100%;border-radius:20px;box-shadow:0 0 10px rgba(0,0,0,0.4);background-color:#F1EEE1;}

/* scrollbar */
.body_preview::v-deep *::-webkit-scrollbar{width:0;height:0;border:0 none;background-color:#f9f9f9} /* 스크롤 바 전체 너비 */
.body_preview::v-deep *::-webkit-scrollbar-thumb{background-color:#ccc;border-radius:10px} /* 현재 게이지 */
.body_preview::v-deep *::-webkit-scrollbar-thumb:hover{background-color:#aaa}
.body_preview::v-deep *::-webkit-scrollbar-thumb:active{background-color:#888}

.body_preview::v-deep .explanation_body .typing_html{opacity:1}
.body_preview::v-deep .explanation_body .desc_explan{display:none}

.foot_preview{padding:20px 0}
.foot_preview .desc_overflow{font-weight:800;cursor:pointer}
.foot_preview .desc_overflow + .desc_overflow{margin-top:12px}
.foot_preview .desc_overflow:hover{text-decoration:underline}

/* 모바일 */
@media all and (max-width:1000px){
  .area_preview{display:none;position:fixed;top:0;bottom:0;left:0;right:0;width:auto;z-index:20;margin:0;background-color:rgba(0,0,0,.86);}
  .area_preview.active{display:block}
  .area_preview.active .body_preview{width:100% !important;height:100% !important}
  
  .head_preview{display:none}
  .swiper-slide{padding:0;font-size:0;white-space:nowrap;text-align:center}
  .swiper-slide:after{display:inline-block;height:100%;vertical-align:middle;content:''}
}
</style>