export default class MapModel {
  constructor(){
    this.objRaw = {};
    this.id = 1;
    this.uid = '';
    this.uniqueName = '';
    this.title = '';
    this.desceiption = '';
    this.imageUrl = null;
    this.thumbnailImageUrl = null;
    this.thumbnailImageUrlList = [];
    this.mapStyle = '';
    this.mapType = '';
    this.status = '';
    this.createdAt = '';
    this.rewardTitle = '';
    this.rewardDescription = '';
    this.extraRewardType = null;
    this.useExtraReward = true;
    this.introductionUrl = '';
    this.sponsorDto = {
      name: '',
      image: '',
      imageSponsorList: []
    };
  }
  setData(obj){
    // if( obj ) this.objRaw = JSON.parse(JSON.stringify(obj));
    const {
      id,
      uid,
      uniqueName,
      title,
      desceiption,
      imageUrl,
      thumbnailImageUrl,
      mapStyle,
      mapType,
      status,
      createdAt,
      rewardTitle,
      rewardDescription,
      extraRewardType,
      useExtraReward,
      introductionUrl,
      sponsorDto
    } = obj;
    this.id = id;
    this.uid = uid;
    this.uniqueName = uniqueName;
    this.title = title;
    this.desceiption = desceiption;
    this.imageUrl = imageUrl;

    if(thumbnailImageUrl){
      this.thumbnailImageUrlList = [
        {
          src : thumbnailImageUrl,
        }
      ]
    }
    this.mapStyle = mapStyle;
    this.mapType = mapType;
    this.status = status;
    this.createdAt = createdAt;
    this.rewardTitle = rewardTitle;
    this.rewardDescription = rewardDescription;
    this.extraRewardType = extraRewardType === '' ? null : extraRewardType;
    this.useExtraReward = useExtraReward;
    this.introductionUrl = introductionUrl;

    const {name,image} = sponsorDto;
    this.sponsorDto.name = name;
    this.sponsorDto.image = image;

    if(image){
      this.sponsorDto.imageSponsorList = [
        {
          src: image
        }
      ]
    }
  }
  setCurrentPlaceId(id){
    this.placeId = id
  }
  getData(){
    if(!this.id){
      return this.getRegisterData()
    }else{
      return this.getModifyData()
    }
  }
  getRegisterData(){
    let obj = {
      title: this.title,
      status: this.status,
      rewardTitle: this.rewardTitle,
      rewardDescription: this.rewardDescription,
      extraRewardType: this.extraRewardType === null ? "" : this.extraRewardType,
      useExtraReward: this.useExtraReward,
      introductionUrl: this.introductionUrl,
      thumbnailImageUrl: this.thumbnailImageUrlList.length > 0 ? this.thumbnailImageUrlList[0].src : '',
      completedImageUrl: this.thumbnailImageUrlList.length > 0 ? this.thumbnailImageUrlList[0].src : '',
      sponsorName: this.sponsorDto.name,
      sponsorImage: this.sponsorDto.imageSponsorList.length > 0 ? this.sponsorDto.imageSponsorList[0].src : '',
    }
    return obj;
  }
  getModifyData(){
    let obj = {
      id: this.id,
      title: this.title,
      status: this.status,
      rewardTitle: this.rewardTitle,
      rewardDescription: this.rewardDescription,
      extraRewardType: this.extraRewardType === null ? "" : this.extraRewardType,
      useExtraReward: this.useExtraReward,
      introductionUrl: this.introductionUrl,
      thumbnailImageUrl: this.thumbnailImageUrlList.length > 0 ? this.thumbnailImageUrlList[0].src : '',
      completedImageUrl: this.thumbnailImageUrlList.length > 0 ? this.thumbnailImageUrlList[0].src : '',
      sponsorName: this.sponsorDto.name,
      sponsorImage: this.sponsorDto.imageSponsorList.length > 0 ? this.sponsorDto.imageSponsorList[0].src : '',
    }
    return obj;
  }
}