<template>
  <div :class="['pagination_comm',`pagination_${quizPageBg.toLowerCase()}`]">
    <div class="area_progress">
      <div
        class="bar_progress"
        :style="{ width: progressWidth }"></div>
    </div>
    <div class="info_progress">
      <div class="txt_progress">Quiz {{ paginationData.page }}</div>
      <div class="group_answer">
        <div
          v-for="n in paginationData.total"
          :key="`pageAnswer${n}`"
          :class="[
            'item_answer',
            { 'item_answer_correct' : selectedAnswerList[n-1] && selectedAnswerList[n-1].checked_answer },
            { 'item_answer_wrong' : selectedAnswerList[n-1] && !selectedAnswerList[n-1].checked_answer },
          ]">
            <span v-if="!selectedAnswerList[n-1]" class="icon_answer"></span>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Pagination',
  props:{
    quizPageBg: String,
    paginationData: Object,
    selectedAnswerList: Array,
  },
  computed:{
    progressWidth(){
      const { progressPage, total } = this.paginationData;
      return ( ( progressPage ) / total * 100 ) +'%'
    },
  },
}
</script>

<style scoped>
@keyframes answerResultShow {
  0%{
    transform:scale(0)
  }
  100%{
    transform:scale(1)
  }
}
.area_progress{height:4px;border-radius:10px;background-color:#E2D7C4}
.area_progress .bar_progress{display:block;height:4px;margin:0;border-radius:10px;background-color:#8F69DD;transition:width 0.8s ease-out}

.info_progress{overflow:hidden;margin-top:4px}
.txt_progress{float:left;font-weight:700;font-size:16px;line-height:24px;color:#8F69DD}
.group_answer{float:right}
.group_answer .item_answer{display:inline-block;width:24px;height:24px;padding:2px;vertical-align:top;box-sizing:border-box}
.group_answer .item_answer + .item_answer{margin-left:2px}
.group_answer .item_answer_correct{background:url('/assets/images/quiz/img_progress_answer_correct.png')no-repeat center;background-size:24px;}
.group_answer .item_answer_wrong{background:url('/assets/images/quiz/img_progress_answer_wrong.png')no-repeat center;background-size:24px;}
.group_answer .item_answer_correct,
.group_answer .item_answer_wrong{transform:scale(0);
  animation-name: answerResultShow;
  animation-duration:0.1s;
  animation-delay:0.3s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
.group_answer .item_answer .icon_answer{display:block;width:20px;height:20px;border-radius:20px;background-color:#E2D7C4}

.pagination_primary200 .area_progress{background-color:#F0EAFA}
.pagination_primary200 .group_answer .item_answer .icon_answer{background-color:#F0EAFA}
</style>