export default class MissionModel {
  constructor(){
    this.guideTitle = ''; // 가이드 타이틀
    this.guideContents = ''; // 가이드 본문
    this.content_attachment = ''; // 가이드_본문 첨부자료
    this.content_attachment_type = ''; // 가이드_본문 첨부자료 타입
    this.outlinkName = ''; // 아웃링크 버튼_버튼명
    this.outlinkUrl = ''; // 아웃링크 버튼_url
    this.grade = 'LOWEST'; // 배점 LOWEST, LOW, MEDIUM, HIGH, HIGHEST
  }
  setData(obj){
    const { id, guideTitle, guideContents, content_attachment, content_attachment_type, outlinkName, outlinkUrl, grade } = obj;
    this.id = id;
    this.guideTitle = guideTitle;
    this.guideContents = guideContents;
    this.content_attachment = content_attachment;
    this.content_attachment_type = content_attachment_type;
    this.outlinkName = outlinkName;
    this.outlinkUrl = outlinkUrl;
    this.grade = grade;
  }
  getRegisterData(){
    let obj = {
      guideTitle : this.guideTitle,
      guideContents : this.guideContents,
      content_attachment : this.content_attachment,
      content_attachment_type : this.content_attachment_type,
      outlinkName : this.outlinkName,
      outlinkUrl : this.outlinkUrl,
      grade : this.grade,
    }
    return obj;
  }
  getModifyData(){
    let obj = {
      guideTitle : this.guideTitle,
      guideContents : this.guideContents,
      content_attachment : this.content_attachment,
      content_attachment_type : this.content_attachment_type,
      outlinkName : this.outlinkName,
      outlinkUrl : this.outlinkUrl,
      grade : this.grade,
    }
    return obj;
  }
}