<template>
  <div :class="['icon_lemontree','icon_'+iconName]" :style="cssProps">
    <svg
      class="svg_icon"
      :width="icoSize"
      :height="icoSize"
      xmlns="http://www.w3.org/2000/svg"
      :aria-labelledby="iconName"
      :viewBox="`0 0 30 30`"
      role="presentation"
    >
      <title :id="iconName" lang="en">{{ iconName }} icon</title>
      <g
        :fill="iconColorSync"
        fill-rule="evenodd">
        <path :d="iconSvgPath"/>
      </g>
    </svg>
  </div>
</template>

<script>
import IconSvgPath from "@/components/common/icon/IconSvgPath";

export default {
  name:'IconSvg',
  props: {
    iconName: {
      type: String,
      default: 'logo'
    },
    size: [Number, String],
    icoSize:[Number, String],
    iconColor: String,
    iconDarkColor: String,
    bgcolor: {
      type: String,
      default: 'none'
    }
  },
  computed:{
    sizeSync(){
      return this.size || this.icoSize
    },
    // isDarkMode(){
    //   return  Boolean(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);
    // },
    iconColorSync(){
      // if(this.isDarkMode){
      //   return this.iconDarkColor || this.iconColor || '#fff';
      // }else{
        return this.iconColor || '#bbb';
      // }
    },
    iconSvgPath(){
      return IconSvgPath[this.iconName];
    },
    cssProps(){
      var obj = {};
      obj = {
        '--width': this.sizeSync+'px',
        '--height': this.sizeSync+'px',
        '--bgcolor': this.bgcolor,
        '--padding': (this.size - this.icoSize)/2 +'px 0',
      }
      return obj;
    },
  }
}
</script>
<style scoped>
.icon_lemontree{display:inline-block;overflow:hidden;width:var(--width);height:var(--height);padding:var(--padding);background-color:var(--bgcolor);vertical-align:top;text-align:center;box-sizing:border-box}
.icon_lemontree svg{display:inline-block;vertical-align:top}
</style>