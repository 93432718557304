<template>
  <div :class="['inner_cont','explanation_body']">
    <strong class="tit_explanation">{{ $poData.quiz_explanation_detailed }}</strong>
    <div class="area_typing">
      <div
        class="typing_html"
        ref="typingElement"
        v-html="selectedAnswerItem.comments"></div>
      <div
        class="desc_explan"
        v-html="comments"></div>
    </div>
    <Button
      v-if="isTypingComment"
      class="btn_next"
      btnSize="medium"
      btnStyle="primary_white"
      text="다음"
      @onClickBtn="$emit('goNextPage')">
      <template v-slot:btn_right>
        <IconSvg iconName="arrow_right" :size="24" :icoSize="16" iconColor="#fff"/>
      </template>
    </Button>
  </div>
</template>

<script>
import Button from '@/components/common/button/Button';
import IconSvg from '@/components/common/icon/IconSvg';

export default {
  name: 'Explanation',
  components:{
    Button,
    IconSvg
  },
  props:{
    selectedAnswerItem: Object,
    isTypingComment: Boolean
  },
  data(){
    return{
      typingTimeOut: null,
      comments:'',
    }
  },
  mounted(){
    this.animateTyping();
  },
  watch:{
    isTypingComment(){
      if(this.isTypingComment){
        this.comments = this.selectedAnswerItem.comments;
        clearTimeout(this.typingTimeOut);
      }
    }
  },
  methods:{
    animateTyping(){
      const enterArray = this.$refs.typingElement.innerText.split('\n\n');
      let typingArray = [];
      enterArray.forEach((line, lineIndex) => {
        const stringArray = line.split(' ');
        stringArray.forEach((text, textIndex) => {
          let textSync = text+' ';
          if(lineIndex != 0 && textIndex === 0){
            textSync = '<br>'+textSync;
          }
          typingArray.push(textSync);
        })
      })
      typingArray.forEach((item, index) => {
        const _this = this;
        this.typingTimeOut = setTimeout(function(){
          if(!_this.isTypingComment){
            _this.comments += item;
            if(typingArray.length === index+1){
              _this.$emit('update:isTypingComment',true);
            }
          }
          this.typingTimeOut = null;
        }, (index+1)*140);
      })
    },
  }
}
</script>

<style scoped>
.explanation_body{padding:30px 20px 64px}
.explanation_body .tit_explanation{display:block;margin-bottom:10px;font-weight:700;font-size:24px;line-height:30px;color:#8F69DD}
.area_typing{position:relative;font-weight:500;font-size:20px;line-height:30px;text-align:left;word-wrap:break-word;word-break:keep-all;white-space:normal}
.explanation_body .typing_html{opacity:0}
.explanation_body .desc_explan{position:absolute;top:0;left:0;right:0}

.btn_next{position:absolute;bottom:48px;right:20px;width:auto;box-shadow:4px 4px 12px rgba(143, 105, 221, 0.64);}
.btn_next .icon_arrow_right{margin:2px 0 0 8px}

/* 높이 640 이하 디바이스 */
/* .short_device .tit_explanation{font-size:20px;line-height:30px}
.short_device .desc_explan{font-size:20px;line-height:30px} */
</style>