<template>
  <Popup
    :title="dataViewModel.id ? '장소 수정' : '새로운 장소 추가'"
    :maxWidth="550"
    closeBtnText="닫기"
    :isDisabeldCompeleteBtn="vaild"
    compeleteBtnText="저장"
    @onClickClose="$emit('onClickPopupClose')"
    @onClickComplete="$emit('onClickPopupComplete',dataViewModel.getData())">
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:160px"/>
        <col style="width:100px"/>
        <col />
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>stage<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td colspan="2">
            <Input
              placeholder="stage"
              :value.sync="dataViewModel.stage"/>
          </td>
        </tr>
        <tr>
          <th>장소명_영어<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td colspan="2">
            <Input
              placeholder="장소명_영어"
              :value.sync="dataViewModel.engName"/>
          </td>
        </tr>
        <tr>
          <th>장소명_한글<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td colspan="2">
            <Input
              placeholder="장소명_한글"
              :value.sync="dataViewModel.name"/>
          </td>
        </tr>
        <tr>
          <th rowspan="2">position</th>
          <th>x</th>
          <td>
            <Input
              placeholder="x"
              :value.sync="dataViewModel.x"
              :isBlockUnValueType="true"
              valueType="number"/>
          </td>
        </tr>
        <tr>
          <th>y</th>
          <td>
            <Input
              placeholder="y"
              :value.sync="dataViewModel.y"
              :isBlockUnValueType="true"
              valueType="number"/>
          </td>
        </tr>
        <tr>
          <th rowspan="2">size</th>
          <th>width</th>
          <td>
            <Input
              placeholder="width"
              :value.sync="dataViewModel.width"
              :isBlockUnValueType="true"
              valueType="number"/>
          </td>
        </tr>
        <tr>
          <th>height</th>
          <td>
            <Input
              placeholder="height"
              :value.sync="dataViewModel.height"
              :isBlockUnValueType="true"
              valueType="number"/>
          </td>
        </tr>
      </template>
    </TableView>
    <template v-slot:footBtn>
      <Button
        v-if="dataViewModel.id"
        class="btn_preview"
        btnSize="small"
        btnStyle="fourthly"
        text="삭제"
        @onClickBtn="viewModel.onClickPlaceDelete(dataViewModel.id)"/>
    </template>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import { pageValidated } from '@lemontree-ai/lemontree-admin-common-front/utils/validateUtils';
import PlaceModel from '@/views/worldMap/contentFolder/model/place/PlaceModel';

export default {
  name:'PlaceRegisterPopup',
  components:{
    Popup,
    TableView,
    IconSvg,
    Input,
    Button,
  },
  props:{
    currentDataViewModel: Object,
    validData: Object
  },
  data(){
    return{
      isModify: false,
      dataViewModel: new PlaceModel(),
    }
  },
  computed:{
    vaild(){
      return pageValidated(this.validData, this.dataViewModel);
    },
  },
  beforeMount(){
    this.dataViewModel.setData(this.currentDataViewModel.PLACE.detailData);
  },
}
</script>