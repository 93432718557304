<template>
  <div class="group_pagination">
    <Button
      class="btn_pagination"
      :disabled="!prevId"
      btnSize="small"
      btnStyle="secondary_border"
      @onClickBtn="$emit('onClickPage',prevId)">
      <IconSvg iconName="arrow_left" :size="14" iconColor="#555"/>
    </Button>
    <span v-if="page != null" class="txt_page">{{ page }} / {{ totalPage }}</span>
    <Button
      class="btn_pagination"
      :disabled="!nextId"
      btnSize="small"
      btnStyle="secondary_border"
      @onClickBtn="$emit('onClickPage',nextId)">
      <IconSvg iconName="arrow_right" :size="14" iconColor="#555"/>
    </Button>
  </div>
</template>
<script>
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';

export default {
  name: 'DetailPagination',
  props:{
    prevId: Number,
    nextId: Number,
    page: Number,
    totalPage: Number
  },
  components:{
    Button,
    IconSvg
  }
}
</script>

<style scoped>
.btn_pagination{padding:6px 10px}
.btn_pagination::v-deep .icon_lemontree{margin:0 0 2px 0}
.txt_page{display:inline-block;margin:0 14px;font-size:14px;line-height:38px;vertical-align:top}
/* 모바일 */
@media all and (max-width:600px){
  .btn_pagination{width:30px;height:30px;margin:3px 0;padding:2px 6px}
  .txt_page{margin:3px 8px;line-height:32px}
}
</style>